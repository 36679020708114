@use 'custom-properties';
@use 'semantic-variables';
@use 'sportsbook';

// base/type

body,
h1,
h2,
h3,
h4,
h5,
h6,
div,
label,
legend,
button,
input,
select,
textarea,
li,
a,
strong,
span,
th,
caption,
td,
p,
footer,
small {
	color: rgba(var(--font-dark), 1);
	font-family: Asap, sans-serif;
}

body {
	background-color: rgba(var(--base-01), 1);

	&.access-blocked {
		background-color: rgba(var(--primary-accent-white), 1);

		h2 {
			color: rgba(var(--font-dark), 1);
			font: var(--h2);
		}

		p {
			color: rgba(var(--font-supportive), 1);
		}

		a {
			color: rgba(var(--font-brand-02), 1);
		}
	}
}

small {
	color: rgba(var(--font-supportive), 1);
}

// components/buttons

.btn {
	background-color: rgba(var(--primary-accent-green), 1);
	color: rgba(var(--base-02), 1);

	&:disabled {
		background-color: rgba(var(--primary-accent-grey-dark), 1);
		color: rgba(var(--base-06), 1);
	}

	&.btn--secondary {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--font-dark), 1);
	}

	&.btn--tertiary {
		background: none;
		color: rgba(var(--font-dark), 1);
	}

	&.btn--clear,
	&.btn--signup,
	&.btn--skip {
		color: rgba(var(--font-brand-02), 1);
	}

	&.btn--login {
		color: rgba(var(--base-02), 1);
	}

	&.btn--logout {
		border-radius: 3px;
	}

	&.btn--light {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--base-01), 1);

		&:hover {
			@media (hover: hover) {
				background: rgba(var(--primary-accent-green-light), 1);
				color: rgba(var(--font-light), 1);
			}
		}
	}

	&.btn--visit {
		background-color: rgba(var(--primary-accent-blue-dark), 1);
		color: rgba(var(--base-01), 1);
	}

	&.btn--small.btn--clip-card {
		background-color: transparent;
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--primary-accent-green), 1);

		&:hover {
			@media (hover: hover) {
				background: rgba(var(--primary-accent-green-light), 1);
				border: 1px solid rgba(var(--primary-accent-green-light), 1);
				color: rgba(var(--font-light), 1);
			}
		}
	}

	&.btn--tutorial-card-button {
		background-color: transparent;
		block-size: fit-content;
		border: 0;
		color: rgba(var(--font-brand-02), 1);
		margin: 14px 0;
		padding: 0;
		text-decoration: underline;
		width: fit-content !important;
	}

	&.btn--action-screen-confirm {
		background-color: rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-light), 1);
	}

	&.btn--responsible-gaming-logout {
		background-color: transparent;
		color: rgba(var(--font-brand-02), 1);
	}
}

// components/campaign

.campaign__item {
	.description {
		color: rgba(var(--base-10), 1);
	}
}

// components/cc-form

.cc-form,
li.deposit-limit {
	&:focus-within .active-border {
		border-bottom: 2px solid rgba(var(--primary-accent-blue), 1);
	}
}

// components/collapse

.welcome-menu {
	.welcome-screen-collapse {
		background-color: rgba(var(--primary-accent-blue-light), 1);

		.welcome-screen__summa-icon {
			fill: rgba(var(--base-02));
		}

		p,
		span {
			color: rgba(var(--base-01), 1);
		}

		.collapse__continue-icon {
			fill: rgba(var(--base-01), 1);
		}

		tr:not(:last-of-type) {
			border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);
		}
	}
}

.deposit-limits-collapse {
	.collapse__continue-icon {
		fill: rgba(var(--font-brand-02), 1);
	}
}

// components/detail-accordion

.details-accordion {
	.summary-icon::after {
		filter: brightness(1%) sepia(1) hue-rotate(62deg) saturate(80);
	}
}

// components/filters

.user-options {
	background-color: rgba(var(--base-01), 1);
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);
}

.filters {
	input {
		+ .filters__filter {
			background-color: rgba(var(--base-01), 1);
			color: rgba(var(--base-10), 1);
			font: var(--label-bold);
		}

		&:checked {
			+ .filters__filter {
				color: rgba(var(--font-brand-01), 1);
			}
		}
	}

	.sliding-border {
		background-color: rgba(var(--primary-accent-blue), 1);
		height: 2px;
	}
}

.shop-product-filters {
	a {
		&::after {
			background-color: var(--filter-bar-inactive);
			bottom: 0;
			content: '';
			height: 1px;
			left: 0;
			position: absolute;
			width: 100%;
		}
	}
}

// components/flags
// components/form

.form {
	input[type='text'],
	input[type='date'],
	input[type='time'],
	input[type='number'],
	select,
	.custom-select select {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--base-09));

		+ label,
		+ legend {
			color: rgba(var(--font-supportive), 1);

			&.error {
				color: rgba(var(--error), 1);
			}
		}

		&:focus {
			color: rgba(var(--primary-accent-blue), 1);

			~ .form__border {
				background-color: rgba(var(--primary-accent-blue-light), 1);
			}
		}

		&:hover {
			border-color: rgba(var(--input-border-hover-color), 1);
		}

		&.input-amount {
			+ label {
				color: rgba(var(--base-01), 1);
			}
		}
	}

	.form__border {
		background-color: rgba(var(--primary-accent-grey-dark), 1);

		&.form__border--error {
			background-color: rgba(var(--error), 1);
		}
	}

	.error {
		color: rgba(var(--error));
	}
}

:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus {
	box-shadow: inset 0 0 0 9999px rgba(var(--base-01), 1) !important;
	-webkit-text-fill-color: rgba(var(--base-10), 1) !important;
}

::-webkit-calendar-picker-indicator {
	filter: invert(62%) sepia(67%) saturate(2707%) hue-rotate(102deg) brightness(101%) contrast(86%);
}

.input-wrap {
	.input-wrap__input {
		border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

		&:focus {
			color: rgba(var(--primary-accent-blue), 1);
		}
	}

	&::after {
		background-color: rgba(var(--primary-accent-blue-light), 1);
	}

	.slider-input {
		background-color: rgba(var(--base-04), 1);
	}
}

li.deposit-limit .input-wrap__input:focus {
	color: rgba(var(--primary-accent-blue-light), 1);
}

.slider-container {
	.icon--sprite {
		fill: rgba(var(--font-brand-02), 1);
	}
}

.legend,
.input-wrap__label {
	color: rgba(var(--base-10), 0.5);
}

.toggle-password {
	svg {
		fill: rgba(var(--primary-accent-blue-light), 1);
	}
}

::-webkit-input-placeholder {
	// color: rgba(var(--font-dark), 1);
	color: rgba(var(--font-supportive), 1);
}

::-moz-placeholder {
	// color: rgba(var(--font-dark), 1);
	color: rgba(var(--font-supportive), 1);
}

:-ms-input-placeholder {
	// color: rgba(var(--font-dark), 1);
	color: rgba(var(--font-supportive), 1);
}

// New checkboxes

.custom-checkbox-list {
	li {
		border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);
	}
}

.toggle-password {
	svg {
		fill: rgba(var(--font-brand-02), 1);
	}
}

// components/game-category
// components/game-item
// components/game-list

.item__footer {
	border-top: 1px solid rgba(var(--base-03), 1);

	.item__love {
		border-right: 1px solid rgba(var(--base-03), 1);

		.item__love-icon {
			fill: rgba(var(--base-06), 1);
		}
	}

	.item__love,
	.item__provider {
		* {
			color: rgba(var(--base-10), 1);
		}
	}
}

.item__love {
	&.game-item__love--selected {
		.item__love-icon {
			fill: rgba(var(--font-brand-02), 1);
		}
	}
}

.item__live-casino {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

	.last-winning-number-container {
		.number-to-appear-text,
		.number-to-disappear-text {
			color: white;
		}
	}
}

.game-item__jackpot {
	background-color: rgba(var(--primary-accent-blue-light), 1);
	color: rgba(var(--base-02), 1);
}

// components/header

.page-head {
	background-color: rgba(var(--primary-accent-blue), 1);

	.btn--skip,
	.btn--login,
	.page-head__title,
	.page-head__right-side {
		color: rgba(var(--base-01), 1);

		span,
		p {
			color: rgba(var(--base-01), 1);
		}
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--base-10), 1);
		}

		.btn--skip,
		.btn--login {
			color: rgba(var(--primary-accent-blue-light), 1);
		}

		svg {
			fill: rgba(var(--base-10), 1);
		}
	}

	&.country-select-header {
		.page-head__title {
			color: rgba(var(--base-10), 1);
		}

		svg {
			fill: rgba(var(--font-brand-02), 1);
		}
	}
}

// components/link-list

.link-list {
	.link-list__item {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		&--rounded {
			border-radius: 3px;
		}

		&.disabled {
			svg {
				fill: rgba(var(--base-04), 1);
			}

			.menu__main-title,
			.link-list__preamble,
			.link-list__continue-icon {
				color: rgba(var(--base-04), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--font-brand-02), 1);
			}

			.link-list__more-icon {
				height: 20px;
				width: 20px;
			}
		}

		svg {
			fill: rgba(var(--primary-accent-blue), 1);
		}

		.menu__main-title {
			color: rgba(var(--base-10), 1);
		}

		.link-list__value,
		.link-list__preamble {
			color: rgba(var(--base-06), 1);
		}

		.link-list__continue-icon {
			fill: rgba(var(--font-brand-02), 1);
		}

		.link-list__status-verified {
			color: rgba(var(--font-brand-02), 1);
		}

		.link-list__status-unverified {
			color: rgba(var(--base-06), 1);
		}

		.dots-container {
			svg {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}
}

.sportsbook-relieve-limits-dialog {
	small {
		font-style: italic;
	}

	.link-list {
		.link-list__item {
			.link-list__preamble {
				font-style: italic;
			}
		}
	}
}

// components/list-options

.list-options {
	.link-list {
		.link-list__item {
			background-color: transparent;
			border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

			.link-list__continue-icon {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}

	.page-head {
		background-color: inherit;
	}
}

// components/my notifications

.cta-button-container {
	background: rgba(var(--base-11), 1);
}

// components/notify

.notification-wrap {
	box-shadow: 0 0 4px black;
	color: rgba(var(--base-02), 1);

	.page-head__close {
		svg {
			fill: #fff;
		}
	}

	p,
	.notification-wrap__error-text-msg {
		color: rgba(var(--base-02), 1);
	}

	.notification-wrap__error-text-header {
		color: rgba(var(--base-02), 1);
	}

	.header-icon {
		svg {
			fill: #fff;
		}
	}

	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}

.my-notification-item-wrapper .my-notification-details .my-notification-title-bar .my-notification-title {
	color: rgba(var(--font-dark), 1);
}

.my-notification-item-wrapper,
.my-notification-details,
.my-notification-title-bar svg {
	fill: rgba(var(--font-brand-02), 1);
}

//notification - inline
.notification-inline-wrap {
	&.red {
		background-color: rgba(var(--error), 1);
	}

	&.green {
		background-color: rgba(var(--success), 1);
	}

	&.neutral {
		background-color: rgba(var(--info), 1);
	}
}
// BINARY QUESTION MODAL

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-01), 1);
	}

	.binary-question__button-container {
		.binary-question__yes,
		.binary-question__no,
		.binary-question__third {
			background: none;
		}
	}
}

// NOTIFY

.notify {
	&.notify--is-open {
		section {
			svg {
				fill: rgba(var(--base-02), 1);
			}

			h2,
			p {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	&.notify--success {
		background-color: rgba(var(--success), 1) !important;
	}

	&.notify--failure {
		background-color: rgba(var(--error), 1) !important;
	}
}

.notify__head {
	.notify__head--quick-deposit {
		fill: var(--section-head-main-icon);
	}
}

// PROGRESS BAR

.notify__progress-bar {
	//background-color: rgba(var(--base-11), 1);
	&.notify__progress-bar--fill-success {
		background-color: rgba(var(--primary-accent-white), 1);
		background-image: none;
	}

	&.notify__progress-bar--fill-fail {
		background-color: rgba(var(--primary-accent-white), 1);
		background-image: none;
	}
}

// OPTION BOX
// OVERLAY BEHIND MODAL

.deposit-siru,
.binary-question {
	&.notify--is-open {
		background-color: rgba(var(--base-11), 0.8);
	}
}

// COOKIE CONSENT
// PAYMENT METHODS

.payment-methods {
	&__item {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		&__logo {
			svg.logo-payment-method {
				background-color: #fff;
			}
		}

		&__chevron {
			svg.link-list__continue-icon {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}
}

// Two Factor auth on execute deposit

.two-fact {
	&__error-label {
		color: rgba(var(--error), 1);
	}

	.btn:disabled {
		background-color: inherit;
	}
}

.notify--is-open {
	&.reality-check-confirm {
		background-color: rgba(var(--primary-accent-white), 1) !important;
		background-image: none;

		.page-head__back {
			svg {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}
}

.notify__p {
	color: rgba(var(--base-06), 1);
}

.option-box {
	background-color: rgba(var(--base-01), 1);

	.option-box__inner {
		border-bottom: 1px solid rgba(var(--base-11), 0.5);
	}

	.btn--secondary {
		background-color: rgba(var(--base-01), 1);
		color: rgba(var(--base-10), 1);
	}

	.user-options {
		background-color: transparent;

		.filters {
			input {
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				+ .filters__filter {
					background-color: rgba(var(--base-05), 1);
				}
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				&:checked {
					//TODO: fix this, reduce nesting REV-318
					//stylelint-disable-next-line selector-max-compound-selectors
					+ .filters__filter {
						background-color: rgba(var(--primary-accent-blue-light), 1);
					}
				}
			}
		}
	}
}

.binary-question {
	.binary-question__inner {
		background-color: rgba(var(--base-01), 1);
	}

	.binary-question__p,
	.input-wrap__input,
	.two-fact__input-wrap input {
		color: rgba(var(--base-10), 1);
	}

	.input-wrap__input,
	.two-fact__input-wrap input {
		background-color: rgba(var(--base-03), 1);
	}

	.binary-question__yes,
	.binary-question__no,
	.binary-question__third {
		border-top: 1px solid rgba(var(--primary-accent-grey-dark), 1);
		color: rgba(var(--base-10), 1);
	}

	.binary-question__yes {
		border-left: 1px solid rgba(var(--primary-accent-grey-dark), 1);
		border-top: 1px solid rgba(var(--primary-accent-grey-dark), 1);
	}
}

.binary-question__inner {
	background-color: turquoise;
}

.notify__icon-wrapper {
	.notify__svg-icon {
		fill: rgba(var(--primary-accent-blue-light), 1);
	}
}

// components/my-activities
.menu {
	&.activity-item-list-container {
		.page-head {
			.page-head__title {
				color: rgba(var(--font-dark), 1);
			}

			svg {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}
}

.activity-bg {
	background-color: rgba(var(--base-01), 1);
}

.bonus-active {
	.offer-tag {
		svg {
			fill: rgba(var(--primary-accent-blue-light), 1);
		}
	}
}

.progress {
	.progress__number,
	.progress__number span {
		color: rgba(var(--base-01), 1);
	}

	svg {
		path {
			stroke: rgba(var(--base-01), 1);
		}

		.progress__meter {
			stroke: rgba(var(--font-brand-02), 1);
		}
	}
}

// components/quick-deposit

.quick-deposit-wrapper {
	background-color: rgba(var(--base-01), 1);

	.quick-deposit-content p {
		color: rgba(var(--font-supportive), 1);
	}
}

.quick-deposit-response {
	&.quick-deposit-response--pending {
		background-color: rgba(var(--base-10), 1);
	}
}

// components/quick-deposit payment methods mobile content

.payment-method-qd-cc-nr {
	color: rgba(var(--font-supportive), 1);
}

// components/scroll-page-head

// search icon

// components/select

.page-head__close {
	.icon--sprite {
		fill: rgba(var(--base-01), 1);
	}
}

/*
.page-head.u-bg-trans {
	.page-head__close {
		.icon--sprite {
			fill: rgba(var(--primary-accent-green), 1);
		}
	}
} */

//product nav
.page-head {
	background-color: rgba(var(--primary-accent-blue), 1);

	.btn--skip,
	.btn--login,
	.page-head__title {
		color: rgba(var(--base-01), 1);
	}

	svg {
		fill: rgba(var(--base-01), 1);
	}

	.page-head__back.u-show {
		svg {
			fill: rgba(var(--font-brand-02), 1);
		}
	}

	&.u-bg-trans {
		.page-head__title {
			color: rgba(var(--base-10), 1);
		}

		.btn--skip,
		.btn--login {
			color: rgba(var(--font-brand-02), 1);
		}

		.deposit-login {
			svg {
				fill: rgba(var(--primary-accent-blue-light), 1);
			}
		}

		svg {
			fill: rgba(var(--font-brand-02), 1);
		}

		&.page-head-welcome,
		&.activity-header {
			svg {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}
}

.page-head--we-spin {
	background-color: var(--sidebar-bg);

	.page-head__title,
	.page-head__title span {
		color: rgba(var(--font-dark), 1);
	}

	.page-head__right-side p {
		color: rgba(var(--font-brand-02), 1);
	}

	svg {
		fill: rgba(var(--primary-accent-green), 1);
	}
}

.custom-select {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

	select {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--base-10), 1);
	}

	select.placeholder-active {
		color: rgba(var(--base-10), 1);
	}

	&::after {
		filter: brightness(0) saturate(100%) invert(80%) sepia(42%) saturate(6887%) hue-rotate(101deg) brightness(100%)
			contrast(86%);
	}
}

// components/spinner
// components/support-nav

.support-nav {
	&.support-nav--is-open {
		background-color: rgba(var(--opacity-overlay), 0.85);
	}

	.support-nav__head {
		h2,
		h3 {
			color: rgba(var(--font-dark), 1);
		}
	}

	.btn--secondary {
		background-color: rgba(var(--base-01), 1);
		color: rgba(var(--font-dark), 1);
	}
}

.dots-container {
	.icon--sprite {
		fill: rgba(var(--font-brand-02), 1);
		height: 20px;
		width: 20px;
	}
}

// components/proposed-locale-nav

.propose-locale-nav {
	.propose-locale-wrapper {
		border-radius: 3px;

		.btn--secondary {
			border-bottom: 0;
			border-top: 1px solid rgba(var(--propose-locale-border, var(--base-07)), 1);
			color: rgba(var(--font-dark), 1);
		}
	}
}
// components/tables

td {
	border-bottom: solid 1px rgba(var(--primary-accent-grey-dark), 1);
	color: rgba(var(--base-10), 1);

	&.u-text-r,
	p {
		color: rgba(var(--font-supportive), 1);
	}

	&:first-of-type {
		font: var(--label-bold);
	}
}

// components/tabs

.tab {
	* {
		color: var(--product-tab-content);
	}

	[aria-selected='true'] {
		* {
			color: rgba(var(--primary-accent-blue), 1);
		}

		svg {
			fill: rgba(var(--primary-accent-blue), 1);
		}
	}
}

// components/tags

.tag {
	background-color: rgba(var(--error), 1);
	color: rgba(var(--base-01), 1);
}

// components/textarea

.form {
	fieldset {
		.grow-wrap:focus-within {
			~ .form__border {
				background-color: rgba(var(--primary-accent-blue-light), 1);
			}
		}
	}

	textarea {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		color: rgba(var(--font-dark), 1);

		+ label,
		+ legend {
			color: rgba(var(--base-06), 1);
		}

		&:focus {
			color: rgba(var(--primary-accent-blue), 1);
		}
	}

	.form__border {
		&:focus-within &.form__border--error {
			background-color: rgba(var(--error), 1);
		}
	}

	.error {
		color: rgba(var(--error));
	}
}

// Autosize textarea
.grow-wrap {
	background-color: rgba(var(--base-03), 1);
	border-bottom: 1px solid rgba(var(--base-08), 1);

	textarea,
	::after {
		color: rgba(var(--base-09));
	}
}

// components/winners

//components/sesson-timer

.session-timer {
	color: rgba(var(--base-06), 1);
}

// layouts/bundles

// layouts/deposit

.deposit {
	.page-head {
		background-color: rgba(var(--primary-accent-blue), 1);
	}

	.page-head.u-bg-trans {
		background-color: transparent;
	}
}

.bonus-item__image-wrapper {
	background-color: rgba(var(--primary-accent-blue-light), 1);
}

// bonus-modal
.bonus-modal-view {
	.collapse__continue-icon {
		fill: rgba(var(--font-brand-02), 1);
	}
}

// layouts/footer

.paragraph-footer,
.paragraph-footer span *,
.footer-legal-content {
	color: rgba(var(--footer-text), 1);

	a {
		color: rgba(var(--font-brand-02), 1);
	}
}

.responsive-footer-logo .licenses-logos__iso-icon {
	background: url('../../mobile-core-assets/img/png/certification-logos/ISOIEC27001_Black.png') no-repeat;
	background-size: 80px 90px;
}

.paragraph-footer span h2 {
	padding-bottom: 4px;
}

.other-logos-footer {
	&.not-payment-logos-footer li {
		background-color: transparent;
	}
}

// layouts/info-box

.info-box {
	background-color: rgba(var(--primary-accent-grey-light), 1);

	.info-box-text,
	.info-box-value {
		color: rgba(var(--font-dark), 1);
	}
}

// layouts/ingame
// layouts/log-in
// layouts/modal-view

.modal-view {
	background-color: rgba(var(--base-01), 1);

	.page-head {
		background-color: inherit;
	}

	&.modal-view--verified {
		background-color: rgba(var(--success), 1);

		div {
			svg {
				fill: rgba(var(--base-02), 1);
			}
		}

		*:not(.btn) {
			color: rgba(var(--base-02), 1);
		}
	}

	&.modal-view--mobile {
		.page-head {
			background-color: rgba(var(--primary-accent-blue), 1);
		}
	}
}

.sportsbook-relieve-limits-dialog,
.time-limits-dialog {
	background-color: rgba(var(--base-01), 1);

	.limit-dialog__back-icon {
		fill: rgba(var(--font-brand-02), 1);
	}
}

//this is to fix color issue on some of the brands logos displaying wrong color under our cookie-settings head
.cookie-settings-header {
	.svg-logo {
		fill: rgba(var(--primary-accent-blue), 1);
	}
}

// layouts/my-limits

.my-limits-menu {
	.limits-notification-wrapper {
		background-color: rgba(var(--primary-accent-grey-light), 1);

		.icon-close--small {
			.icon--sprite {
				fill: rgba(var(--font-brand-02), 1);
			}
		}

		* {
			color: rgba(var(--base-10), 1);
		}

		svg.limits-notification-icon {
			fill: rgba(var(--primary-accent-blue), 1);
		}

		small {
			color: rgba(var(--font-supportive), 1);
		}
	}

	.accordion {
		.accordion__link {
			background-color: rgba(var(--base-03), 1);
		}

		.accordion__content {
			background-color: rgba(var(--base-03), 1);
		}
	}

	button {
		color: rgba(var(--primary-accent-green), 1);
	}
}

// layouts/menu
// layouts/off-canvas

.menu {
	background-color: rgba(var(--base-01), 1);

	.page-head {
		background-color: inherit;

		svg {
			fill: rgba(var(--font-brand-02), 1);
		}

		.svg-logo {
			fill: rgba(var(--primary-accent-blue), 1);
		}
	}
}

.last-login-timer {
	&--text,
	&--status {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/gaming-habits

.gaming-habits,
.playing-behaviour {
	.page-head {
		svg {
			fill: rgba(var(--font-brand-02), 1);
		}
	}
}
// layouts/off-canvas-account

.user-balance {
	.user-balance__title {
		color: rgba(var(--base-06), 1);
	}

	.user-balance__sum {
		color: rgba(var(--font-dark), 1);
	}
}

.account-icon-header {
	color: rgba(var(--base-01), 1);
}

.session-timer {
	color: rgba(var(--base-04), 1);

	&.session-timer--regulation-header {
		color: rgba(var(--base-12), 1);

		.session-timer-time {
			color: rgba(var(--base-01), 1);
		}
	}
}

// layouts/payment
// layouts/pusher

// layouts/radio-box

.radio-box {
	background-color: rgba(var(--base-03), 1);

	h5 {
		color: rgba(var(--font-brand-02), 1);
	}

	p {
		color: rgba(var(--font-dark), 1);
	}

	small {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/recover-password
// layouts/result

[class^='section-head__SubTitle'],
.u-result-header {
	color: rgba(var(--font-supportive), 1);
}

.result {
	&.result--is-open {
		.failed-deposit {
			*:not(.btn),
			.btn- {
				color: rgba(var(--base-11), 1);
			}

			svg:not(.logo-payment-method) {
				fill: rgba(var(--base-11), 1);
			}
		}

		svg {
			fill: rgba(var(--primary-accent-blue-light), 1);
		}

		.btn--secondary {
			color: rgba(var(--base-01), 1);
		}
	}
}

.js-payment-result-information-message {
	.js-try-again-button-header {
		color: rgba(var(--font-brand-02), 1);
	}

	.payment-result-header,
	.js-balance-text,
	p {
		color: rgba(var(--font-supportive), 1);
	}
}

.js-notify__button-wrapper--failed {
	.btn.btn--secondary {
		margin-bottom: 12px;

		&:first-child {
			border-radius: var(--button-border-radius);
		}
	}

	svg {
		fill: rgba(var(--font-brand-02), 1);
	}
}

.payment__form {
	.ReactCreditCard {
		&__display {
			color: rgba(var(--base-10), 1);
			opacity: 1;
		}
	}
}

small.payment__amount-info--small,
button.u-text-grey {
	color: var(--base-10);
}

// layouts/shop

.points__svg {
	fill: rgba(var(--primary-accent-blue-light), 1);

	text {
		fill: rgba(var(--base-02), 1);
	}
}

.offer-tag {
	svg {
		fill: rgba(var(--base-10), 1);
	}
}

// layouts/signup

.bottom-wrapper {
	background-color: rgba(var(--base-01), 1);

	&.signup--open,
	&.log-in--open {
		background-color: initial;
	}
}

// layouts/sportsbook
// layouts/static-text

.wrapper-about,
.wrapper-responsible-gaming,
.wrapper-privacy_policy,
.wrapper-terms-and-conditions,
.wrapper-cookie-policy {
	.adaptable-header--pinned {
		--page-head-icon: rgba(var(--font-brand-02), 1);
		--page-head-link: rgba(var(--font-brand-02), 1);
		--page-head-title: transparent;

		background-color: rgba(var(--base-01), 1);
		border-bottom-color: transparent;

		.filter-page-head-back svg {
			fill: rgba(var(--font-brand-02), 1);
		}
	}

	.adaptable-header--unfixed {
		svg {
			fill: rgba(var(--base-01), 1);
		}
	}
}

.static-text.terms-conditions {
	.page-head {
		background-color: rgba(var(--primary-accent-blue), 1);

		svg {
			fill: rgba(var(--base-01), 1);
		}

		.svg-logo {
			fill: rgba(var(--base-01), 1);
		}

		&.u-bg-trans {
			background-color: var(--sidebar-bg);

			svg {
				fill: rgba(var(--font-brand-02), 1);
			}
		}
	}
}

// layouts/titles

.disclaimer-text {
	color: rgba(var(--font-brand-02), 1);
	text-decoration: underline;
}

// layouts/transaction-history

.transaction-history-inactive-layer {
	background-color: rgba(var(--base-11), 1);
}

.transaction-modal {
	background-color: rgba(var(--base-01), 1);
}

// layouts/upload

.template-document-wrapper {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

	&::after {
		background-color: rgba(var(--font-brand-02), 1);
	}
}

// layouts/welcome-screen

.welcome-menu {
	background-color: rgba(var(--primary-accent-blue), 1);

	.accordion {
		.accordion__link,
		.accordion__content {
			background-color: rgba(var(--primary-accent-blue-light), 1);
			color: rgba(var(--base-02), 1);
			//TODO: fix this, reduce nesting REV-318
			//stylelint-disable-next-line selector-max-compound-selectors
			tr {
				border-bottom: solid 1px rgba(var(--primary-accent-grey-dark), 1);
				//TODO: fix this, reduce nesting REV-318
				//stylelint-disable-next-line selector-max-compound-selectors
				svg {
					fill: rgba(var(--base-02), 1);
				}
			}

			span {
				color: rgba(var(--base-02), 1);
			}
		}
	}

	.link-list {
		.link-list__continue-icon {
			fill: rgba(var(--base-02), 1);
		}

		.link-list__item {
			background-color: rgba(var(--primary-accent-blue-light), 1);
			color: rgba(var(--base-02), 1);

			.menu__main-title {
				color: rgba(var(--base-02), 1);
			}

			.link-list__continue-icon {
				fill: rgba(var(--base-02), 1);
			}
		}
	}
}

// layouts/wespin

.filters-no-results svg {
	fill: rgba(var(--primary-accent-blue), 1);
}

.item--large-we-spin {
	&.tutorial-card {
		small,
		p,
		h3 {
			color: rgba(var(--font-light), 1);
		}

		#tutorial-card-link {
			color: rgba(var(--base-01), 1);
		}
	}
}

.wrapper-we-spin {
	.transparent_title {
		&.page-head--opac {
			.dots-container {
				svg {
					fill: rgba(var(--base-01), 1);
				}
			}
		}
	}
}

.top-of-page-image-wrapper {
	.fade-image {
		&::after {
			background: linear-gradient(to top, rgba(var(--base-01), 1) 10%, rgba(var(--base-01), 0));
		}
	}

	.wespin-streamelement {
		small,
		h3 {
			color: rgba(var(--font-light, var(--base-01)), 1);
		}

		p,
		span {
			color: rgba(var(--font-light), 1);
		}
	}
}

.wespin-eventlist-date {
	h5 {
		color: rgba(var(--base-10), 1);
	}
}

.wespin-scheduled-item-list > .basic-horizontal-card-wrapper {
	.basic-horizontal-card--top-text,
	.basic-horizontal-card--middle-text {
		* {
			color: rgba(var(--base-10), 1);
		}
	}

	.basic-horizontal-card--bottom-text {
		* {
			color: rgba(var(--font-supportive), 1);
		}
	}
}

.wespin-gameroom-footer-prejoin,
.wespin-gameroom-footer-stream-preview {
	div {
		h5,
		h5 > span {
			color: rgba(var(--base-10), 1);
		}
	}

	button {
		span {
			color: rgba(var(--base-01), 1);
		}
	}
}

.wespin-gameroom-footer-prejoin,
.wespin-gameroom-footer-stream-preview {
	border-top: 1px solid rgba(var(--primary-accent-grey-light), 1);
}

.wespin-gameroom-preview > .info-box.edit-buy-in > .edit-buyin-button,
.buy-in-buttons div .btn {
	&.btn--small {
		background-color: rgba(var(--primary-accent-green), 1);
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-light), 1);
	}

	&.btn--small.edit-buyin-button,
	&.btn--light {
		background-color: transparent;
		border: 1px solid rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-brand-02), 1);
		@media (hover: hover) {
			&:hover {
				background: rgba(var(--primary-accent-green-light), 1);
				border: 1px solid rgba(var(--primary-accent-green-light), 1);
				color: rgba(var(--font-light), 1);
			}
		}
	}
}

.wespin-buyin-form,
.wespin-gameroom-preview {
	.collapse__continue-icon,
	.collapse__info-icon {
		fill: rgba(var(--font-brand-02), 1);
	}

	.we-spin-game-room__collapse {
		margin-top: 16px;
	}

	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

.wespin-large-buyin-button-container {
	border-top: 1px solid rgba(var(--primary-accent-grey-light), 1);
}

.lobby-card__streamer-name {
	color: rgba(var(--primary-accent-blue-light), 1);
}

/* Streamer profile page */
.streamer-presentation {
	h3 {
		color: rgba(var(--primary-accent-blue), 1);
	}

	p {
		color: rgba(var(--font-supportive), 1);
	}
}

.display-circle {
	background-color: rgba(var(--base-03), 1);
}

.display-circle--svg {
	background-color: rgba(var(--base-03), 1);

	svg {
		fill: rgba(var(--base-01));
	}
}

.buy-in-link {
	color: rgba(var(--primary-accent-blue-light), 1);
}

.buy-in-link__disabled {
	color: rgba(var(--base-02), 1);
}

.we-spin-edit-stream-modal {
	button,
	h3,
	p {
		color: rgba(var(--base-01), 1);
	}
}

.wespin-tester-create-stream-info {
	color: rgba(var(--base-01), 1);
}

/* Settings */
.upload-file {
	input[type='file'] + label {
		background-color: rgba(var(--primary-accent-grey-light), 1);
		border-bottom: 2px solid rgba(var(--primary-accent-grey-dark), 1);
		color: rgba(var(--font-dark), 1);
	}
}

.new {
	background-color: #a864ff;
	color: rgba(var(--base-01), 1);
}

/* Creating a streamer success page */
.create-streamer-success {
	.page-head__left-side {
		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	.create-streamer-success-body {
		p,
		h3 {
			color: rgba(var(--base-01), 1);
		}

		svg {
			fill: rgba(var(--base-01), 1);
		}
	}

	footer {
		button {
			background-color: rgba(var(--primary-accent-blue-light), 1);
		}
	}
}

.chevron-round {
	background-color: rgba(var(--primary-accent-blue-light), 1);

	svg {
		fill: rgba(var(--base-01, 1)) !important;
	}
}

.chevron-round__disabled {
	background-color: rgba(var(--base-11), 1);
}

.wespin-social-share {
	button.btn--secondary {
		background-color: transparent;
		border: solid 1px rgba(var(--primary-accent-green), 1);
		color: rgba(var(--font-brand-02), 1);
	}
}

.game-view {
	h5 {
		color: rgba(var(--base-01), 1);
	}

	svg {
		fill: rgba(var(--base-01), 1);
	}
}

.wespin-sidebar .page-head.u-bg-trans .wespin-gameroom-title {
	visibility: hidden;
}

.buy-in-buttons,
.live-dot {
	small {
		color: rgba(var(--font-supportive), 1);
	}
}

.avatar-circle {
	border: 2px solid rgba(var(--primary-accent-blue), 1);

	.avatar-circle__flag {
		border: 2px solid rgba(var(--primary-accent-blue), 1);
	}
}

.create-events-button,
.wespin-upload-file--button {
	background-color: transparent;
	border: 1px solid rgba(var(--primary-accent-green), 1);
	color: rgba(var(--font-brand-02), 1);

	&:hover {
		@media (hover: hover) {
			border: 1px solid rgba(var(--primary-accent-green-light), 1);
			color: rgba(var(--primary-accent-green-light), 1);
		}
	}
}

.BONUS_HUNT {
	background-image: radial-gradient(circle, rgba(128, 79, 193, 100%) 30%, rgba(220, 22, 199, 100%) 100%);
}

.BONUS_BUY {
	background-image: radial-gradient(circle, rgba(128, 79, 193, 100%) 30%, rgba(220, 22, 199, 100%) 100%);
}

.JACKPOT_HUNT {
	background-image: radial-gradient(circle, rgba(231, 242, 51, 100%) 30%, rgba(132, 233, 34, 100%) 100%);
}

.STREAMERS_CHOICE {
	background-image: linear-gradient(to right, rgb(0, 122, 173) 0%, rgb(165, 223, 249) 50%, rgb(0, 122, 173) 100%);
}

// Wespin EventTypeTitle BG color
.event-type-title-bg {
	background: rgba(var(--base-10), 1);
}

// Onboarding
button {
	&.onboarding-invisible-secondary-button {
		color: rgba(var(--font-brand-02), 1);
	}
}

// layouts/withdrawal

.pending-withdrawal {
	border-bottom: 1px solid rgba(var(--primary-accent-grey-dark), 1);

	.pending-withdrawal__action {
		svg {
			fill: rgba(var(--font-brand-02), 1);
		}
	}

	.pending-withdrawal__amount {
		color: rgba(var(--font-supportive), 1);
	}
}

// layouts/SEO content

.seo-content {
	a {
		color: rgba(var(--seo-read-more-btn), 1);
	}
}

// utils/placeholders
// utils/utility-classes

.u-box-bg {
	background-color: rgba(var(--base-01), 1);
}

.u-text-grey {
	color: rgba(var(--base-06), 1);
}

.u-divider {
	border-color: rgba(var(--base-04), 1);
}

// swish-logo

.swish-logo {
	.text-color {
		fill: rgba(var(--base-10), 1);
	}
}

//external iframe request
.external-iframe.page-head {
	.page-head__title .svg-logo {
		fill: rgba(var(--primary-accent-blue), 1);
	}
}

.payment-tag {
	color: rgba(var(--font-light), 1);
	font-style: italic;
	font-weight: 400;

	&__popular {
		background-color: rgba(var(--primary-accent-blue-light), 1);
	}

	&__no-fee {
		background-color: rgba(var(--font-brand-02), 1);
	}
}

// Responsible gaming

.my-limits-menu {
	.collapse-value {
		color: rgba(var(--font-supportive), 1);
	}
}

// Transaction History
.selected-filter-tags {
	span.selected-filter-tags_text {
		color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
	}

	button::after {
		color: rgba(var(--font-brand-02), 1) !important;
	}
}

.pill.disable span {
	color: rgba(var(--filtering-pill-font-color-dark), 1) !important;
}

// Black friday
.black-friday-header {
	background-color: rgba(var(--base-12), 1) !important;
	border-bottom: 1px solid var(--base-12) !important;
}

.mobile-search-container {
	background-color: var(--search-input-wrapper-mobile, transparent);
}

.mobile-search-container--black-friday {
	background-color: rgba(var(--base-12), 1);
}
