:root {
	--sportsbook-bg-primary: rgba(var(--primary-accent-grey-light), 1);
	--sportsbook-bg-secondary: rgba(var(--base-01), 1);
	--sportsbook-bg-tertiary: rgba(var(--primary-accent-grey-light), 1);
	--sportsbook-bg-accent: rgba(var(--primary-accent-blue), 1);
	--sportsbook-text-accent: rgba(var(--primary-accent-blue-light), 1);
	--sportsbook-icon-primary: rgba(var(--font-brand-02), 1);
	--sportsbook-border-primary: rgba(var(--primary-accent-grey-dark), 1);
	--sportsbook-border-secondary: rgba(var(--primary-accent-grey-dark), 1);

	// Explore
	--sportsbook-explore-featured-link-text-color: rgba(var(--secondary), 1);

	// Desktop Menu
	--sportsbook-desktop-menu-item-icon-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-desktop-menu-chevron-color: rgba(var(--font-brand-02), 1);
	--sportsbook-desktop-menu-header-link-color: rgba(var(--font-brand-02), 1);

	// Betslip
	--sportsbook-betslip-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-betslip-header-bets-count-circle-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-minimized-betslip-header-bg-color: rgba(var(--primary-accent-green), 1);
	--sportsbook-minimized-betslip-header-bets-count-label-color: rgba(var(--font-brand-02), 1);
	--sportsbook-betslip-clear-selections-button-text: rgba(var(--font-brand-02), 1);
	--sportsbook-betslip-place-bets-button-disabled-bg: rgba(var(--primary-accent-grey-dark), 1);
	--sportsbook-betslip-place-bets-button-disabled-text: rgba(var(--font-disabled), 1);
	--sportsbook-betslip-freebet-select-bg: var(--sportsbook-bg-secondary);
	--sportsbook-betslip-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-betslip-header-icon-color: rgba(var(--font-brand-02), 1);
	--sportsbook-betslip-radio-button-color: rgba(var(--font-brand-02), 1);
	--sportsbook-betslip-placement-my-bets-label-color: rgba(var(--font-brand-02), 1);
	--sportsbook-betslip-placement-state-color-placing: rgba(var(--primary-accent-blue), 1);
	--sportsbook-is-prominent-color: rgba(var(--font-brand-02), 1);

	// Share betslip
	--sportsbook-share-betslip-copy-input-bg: rgba(var(--input-background-color), 1);

	// Event
	--sportsbook-event-statistics-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-event-dropdown-icon-color: rgba(var(--font-brand-02), 1);
	--sportsbook-event-bet-builder-prompt-icon-color: rgba(var(--secondary), 1);

	// Event page
	--sportsbook-event-scoreboard-serve-indicator: rgba(var(--font-brand-02), 1);

	// Top Bar
	--sportsbook-top-bar-bg-color: var(
		--base-01
	); // Intentionally set this as rgb because we need to be able to use different alpha values

	// Browse
	--sportsbook-browse-link-icon-color: rgba(var(--font-brand-02, 1));
	--sportsbook-browse-link-sport-icon-color: rgba(var(--primary-accent-blue), 1);

	// My-bets
	--sportsbook-my-bets-show-more-color: rgba(var(--font-brand-02), 1);
	--sportsbook-my-bets-unauthenticated-icon-color: rgba(var(--primary-light), 1);
	--sportsbook-button-group-tab-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-cashout-button-text-color: var(--button-primary-text);
	--sportsbook-my-bets-cashout-button-text-color-active: var(--sportsbook-text-main);
	--sportsbook-my-bets-expand-color: var(--primary-accent-grey-light);
	--sportsbook-my-bets-share-button-bg: rgba(var(--primary-accent-green), 1);

	// My-bets Share-Bet
	--sportsbook-my-bets-share-bet-card-icon-fill: rgba(var(--base-04), 1);
	--sportsbook-my-bets-share-bet-card-logo-fill: rgba(var(--primary-accent-blue), 1);

	// Sportsbook CarouselItem
	--sportsbook-carousel-promotion-combo-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-carousel-promotion-combo-label-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-combo-event-name-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-link-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-carousel-promotion-link-label-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-header-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-carousel-promotion-header-title-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-header-label-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-badge-bg-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-carousel-promotion-badge-text-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-nbn-title-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-nbn-subtitle-color: var(--sportsbook-text-contrast);
	--sportsbook-carousel-promotion-nbn-bg-color: var(--sportsbook-bg-accent);

	// Precanned Carousel
	--sportsbook-carousel-card-icon-fill: rgba(var(--base-04), 1);

	// Precanned market
	--sportsbook-odds-range-pill-bg: var(--sportsbook-bg-secondary);

	// Recommended cards
	--sportsbook-recommended-card-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-recommended-card-bg-gradient-top-color: rgba(var(--primary-accent-grey-dark), 0.15);
	--sportsbook-recommended-card-bg-gradient-bottom-color: rgba(var(--secondary), 0.15);
	--sportsbook-recommended-card-header-bg-gradient-left-color: rgba(var(--primary-accent-grey-dark), 0.5);
	--sportsbook-recommended-card-header-bg-gradient-right-color: rgba(var(--primary-accent-grey-dark), 0);
	--sportsbook-recommended-card-border-color: rgba(var(--base-12), 0.05);
	--sportsbook-recommended-card-icon-bg-color: rgba(var(--secondary), 1);
	--sportsbook-recommended-card-icon-color: rgba(var(--base-01), 1);

	// Search
	--sportsbook-search-suggestions-history-icon-color: var(--sportsbook-text-primary);
	--sportsbook-search-input-icon-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-search-input-placeholder-color: rgba(var(--font-supportive), 1);
	--sportsbook-search-filter-pill-bg-color: var(--sportsbook-bg-secondary);

	// Sportsbook Maintenance
	--sportsbook-maintainance-product-bg-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-maintainance-border-color: var(--sportsbook-text-supportive);

	// Event Widget
	--sportsbook-event-widget-button-color: rgba(var(--base-08), 1);

	// Bet builder
	--sportsbook-betbuilder-close-icon: rgba(var(--font-brand-02), 1);

	// Other
	--sportsbook-live-color: rgba(var(--primary-accent-pink), 1);
	--sportsbook-live-text-color: rgba(var(--base-02), 1);
	--sportsbook-tennis-serve-indicator-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-loading-spinner-color: rgba(var(--primary-accent-white), 1);
	--sportsbook-navigation-tab-active-color: rgba(var(--primary-accent-blue), 1);
	--sportsbook-carousel-slider-icon-fill: var(--font-brand-02);
}
