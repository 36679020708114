:root {
	--sportsbook-bg-primary: rgba(var(--base-01), 1);
	--sportsbook-bg-secondary: rgba(var(--base-02), 1);
	--sportsbook-bg-tertiary: rgba(var(--base-03), 1);
	--sportsbook-bg-accent: rgba(var(--primary), 1);
	--sportsbook-root-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-text-main: rgba(var(--font-dark), 1);
	--sportsbook-text-supportive: rgba(var(--font-supportive), 1);
	--sportsbook-text-contrast: rgba(var(--font-light), 1);
	--sportsbook-text-accent: rgba(var(--primary), 1);
	--sportsbook-icon-primary: rgba(var(--primary), 1);
	--sportsbook-rounded-sm: 3px;
	--sportsbook-rounded-md: 6px;
	--sportsbook-rounded-lg: 12px;
	--sportsbook-border-primary: rgba(var(--base-03), 1);
	--sportsbook-border-secondary: rgba(var(--base-06), 1);
	--sportsbook-shadow-primary: 0 8px 10px -5px rgb(0 0 0 / 20%), 0 16px 24px 2px rgb(0 0 0 / 14%),
		0 6px 30px 5px rgb(0 0 0 / 12%);
	--sportsbook-shadow-secondary: 0 0 8px 1px rgba(0, 0, 0, 25%);

	// Explore

	// Checkbox
	--sportsbook-checkbox-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-checkbox-label-color: var(--sportsbook-text-main);

	// Navigation
	--sportsbook-navigation-back-button-color: var(--sportsbook-icon-primary);

	// Breadcrumbs
	--sportsbook-breadcrumbs-text-color: var(--sportsbook-text-supportive);
	--sportsbook-breadcrumbs-text-color-hover: var(--sportsbook-text-main);

	// Desktop Menu
	--sportsbook-desktop-menu-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-desktop-menu-item-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-list-item-text-color: var(--sportsbook-text-main);
	--sportsbook-desktop-menu-item-icon-color: var(--sportsbook-text-main);
	--sportsbook-desktop-menu-list-title-color: var(--sportsbook-text-main);
	--sportsbook-desktop-menu-header-link-color: var(--sportsbook-text-accent);
	--sportsbook-desktop-menu-chevron-color: var(--sportsbook-icon-primary);

	// Betslip
	--sportsbook-betslip-bg-color: var(--sportsbook-bg-secondary);
	--sportsbook-betslip-border: var(--sportsbook-border-primary);
	--sportsbook-betslip-header-title-color: var(--sportsbook-text-main);
	--sportsbook-betslip-header-bets-count-label-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-header-bets-count-circle-color: var(--sportsbook-bg-tertiary);
	--sportsbook-betslip-header-icon-color: var(--sportsbook-text-main);
	--sportsbook-minimized-betslip-header-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-minimized-betslip-header-title-color: var(--sportsbook-text-contrast);
	--sportsbook-minimized-betslip-header-bets-count-label-color: var(--sportsbook-text-accent);
	--sportsbook-minimized-betslip-header-bets-count-circle-color: var(--sportsbook-bg-secondary);
	--sportsbook-minimized-betslip-header-icon-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-clear-selections-button-text: var(--sportsbook-text-accent);
	--sportsbook-betslip-remove-icon: var(--sportsbook-text-supportive);
	--sportsbook-betslip-restriction-icon: rgba(var(--info), 1);
	--sportsbook-betslip-freebet-select-bg: var(--sportsbook-bg-primary);
	--sportsbook-betslip-message-bg-color: rgba(var(--info), 1);
	--sportsbook-betslip-message-text-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-message-action-button-bg-color: rgba(49, 55, 55, 80%);
	--sportsbook-betslip-message-action-button-text-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-place-bets-button-disabled-bg: var(--button-disabled-bg);
	--sportsbook-betslip-place-bets-button-disabled-text: var(--button-disabled-text);
	--sportsbook-is-prominent-color: rgba(var(--primary), 1);
	--sportsbook-betslip-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-betslip-spinner: rgba(var(--font-light), 1);

	// We use them to color bet indicator and header icon circle with respect to placement state
	--sportsbook-betslip-placement-state-color-placing: rgba(var(--primary), 1);
	--sportsbook-betslip-placement-state-color-placed: rgba(var(--success), 1);
	--sportsbook-betslip-placement-state-color-new-offer: rgba(var(--info), 1);
	--sportsbook-betslip-placement-state-color-declined: rgba(var(--error), 1);
	--sportsbook-betslip-placement-state-color-unknown: rgba(var(--error), 1);
	--sportsbook-betslip-placement-my-bets-label-color: var(--sportsbook-text-accent);
	--sportsbook-betslip-placement-icon-new-offer-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-placement-icon-declined-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-placement-icon-unknown-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-placement-interaction-bg-error-color: rgba(var(--error), 1);
	--sportsbook-betslip-placement-interaction-bg-info-color: rgba(var(--info), 1);
	--sportsbook-betslip-placement-interaction-text-error-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-placement-interaction-text-info-color: var(--sportsbook-text-contrast);
	--sportsbook-betslip-placement-new-offer-highlighted-color: rgba(var(--info), 1);
	--sportsbook-betslip-placement-new-offer-button-color: rgba(49, 55, 55, 80%); // sbtech
	--sportsbook-betslip-placement-new-offer-button-text-color: var(--sportsbook-text-contrast); // sbtech

	// Share betslip
	--sportsbook-share-betslip-copy-input-bg: var(--sportsbook-bg-primary);
	--sportsbook-share-betslip-copy-input-icon-color: var(--sportsbook-icon-primary);

	// Event
	--sportsbook-event-status-indicator-bg-color: var(--sportsbook-bg-tertiary);
	--sportsbook-event-status-indicator-text-color: var(--sportsbook-text-supportive);
	--sportsbook-event-scoreboard-score-color: var(--sportsbook-text-main);
	--sportsbook-event-scoreboard-participant-color: var(--sportsbook-text-main);
	--sportsbook-event-scoreboard-total-score-bg: var(--sportsbook-bg-secondary);
	--sportsbook-event-scoreboard-total-score-alternative-bg: var(--sportsbook-bg-primary);
	--sportsbook-event-statistics-icon-color: var(--sportsbook-text-main);
	--sportsbook-event-dropdown-icon-color: rgba(var(--sportsbook-icon-primary), 1);
	--sportsbook-event-bet-builder-prompt-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-event-more-events-backdrop-text-color: var(--sportsbook-text-contrast);

	// Top Bar
	--sportsbook-top-bar-bg-color: var(
		--base-02
	); // Related to --sportsbook-bg-secondary. Intentionally set this as rgb because we need to be able to use different alpha values.

	--sportsbook-top-bar-button-bg: var(--pill-bg);

	// Browse
	--sportsbook-browse-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-browse-link-color: var(--sportsbook-text-main);
	--sportsbook-browse-link-icon-color: var(--sportsbook-text-main);
	--sportsbook-browse-leagues-expanded-color: var(--sportsbook-text-accent);

	// Sport All Leagues
	--sportsbook-leagues-by-region-menu-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-leagues-by-region-region-bg-color: var(--sportsbook-bg-secondary);

	// My-bets
	--sportsbook-button-group-tab-bg: var(--sportsbook-bg-tertiary);
	--sportsbook-button-group-tab-active-bg: var(--sportsbook-bg-primary);
	--sportsbook-button-group-tab-label-color: var(--sportsbook-text-accent);
	--sportsbook-my-bets-settlement-status-open-color: rgba(var(--pending), 1);
	--sportsbook-my-bets-settlement-status-won-color: rgba(var(--success), 1);
	--sportsbook-my-bets-settlement-status-lost-color: rgba(var(--error), 1);
	--sportsbook-my-bets-settlement-status-cancelled-color: var(--sportsbook-text-supportive);
	--sportsbook-my-bets-show-more-color: var(--sportsbook-icon-primary);
	--sportsbook-my-bets-unauthenticated-icon-color: var(--sportsbook-text-main);
	--sportsbook-my-bets-unauthenticated-text-color: var(--sportsbook-text-main);
	--sportsbook-my-bets-ticket-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-ticket-border-color: var(--sportsbook-border-primary);
	--sportsbook-my-bets-cashout-button-text-color: var(--sportsbook-text-contrast);
	--sportsbook-my-bets-cashout-button-text-color-active: var(--sportsbook-text-main);
	--sportsbook-my-bets-share-button-bg: var(--button-secondary-bg);
	--sportsbook-my-bets-share-text: var(--button-primary-text);
	--sportsbook-my-bets-share-icon: var(--button-primary-text);
	--sportsbook-my-bets-login-prompt-icon-color: var(--sportsbook-icon-primary);

	// My-bets Share-Bet
	--sportsbook-my-bets-share-bet-wrapper-bg: var(--sportsbook-bg-secondary);
	--sportsbook-my-bets-share-bet-header-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-share-bet-footer-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-share-bet-card-bg: var(--sportsbook-bg-primary);
	--sportsbook-my-bets-share-bet-card-icon-fill: rgba(var(--base-03), 1);
	--sportsbook-my-bets-share-bet-card-logo-fill: var(--sportsbook-icon-primary);

	// Sportsbook filter bar
	--sportsbook-scroll-direction-arrow-bg: var(--sportsbook-bg-accent);
	--sportsbook-scroll-direction-arrow-color: var(--sportsbook-text-contrast);

	// Sportsbook CarouselItem
	--sportsbook-carousel-promotion-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-combo-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-combo-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-link-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-link-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-header-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-carousel-promotion-header-title-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-header-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-single-label-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-description-bg-color: var(
		--base-01
	); // Related to sportsbook-bg-primary. Intentionally set this as rgb because we need to be able to use different alpha values

	--sportsbook-carousel-promotion-description-text-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-badge-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-carousel-promotion-badge-text-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-nbn-title-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-nbn-subtitle-color: var(--sportsbook-text-main);
	--sportsbook-carousel-promotion-nbn-bg-color: var(--sportsbook-bg-primary);

	// Precanned Carousel
	--sportsbook-carousel-card-icon-fill: rgba(var(--base-03), 1);

	// Recommended cards
	--sportsbook-recommended-card-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-recommended-card-bg-gradient-top-color: rgba(var(--primary-accent-grey-dark), 0.15);
	--sportsbook-recommended-card-bg-gradient-bottom-color: rgba(var(--primary), 0.15);
	--sportsbook-recommended-card-header-bg-gradient-left-color: rgba(var(--primary-accent-grey-dark), 0.5);
	--sportsbook-recommended-card-header-bg-gradient-right-color: rgba(var(--primary-accent-grey-dark), 0);
	--sportsbook-recommended-card-border-color: rgba(var(--base-12), 0.05);
	--sportsbook-recommended-card-icon-bg-color: rgba(var(--primary), 1);
	--sportsbook-recommended-card-icon-color: rgba(var(--base-01), 1);

	// Sportsbook Search
	--sportsbook-search-fullscreen-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-search-input-bg-color: var(--sportsbook-bg-primary);
	--sportsbook-search-input-border-color: rgba(
		var(--base-11),
		1
	); // we don't have correct color defined anywhere, so we use base-11.

	--sportsbook-search-suggestions-history-icon-color: rgba(var(--primary-light), 1);
	--sportsbook-search-suggestions-clear-icon-color: var(--sportsbook-icon-primary);
	--sportsbook-search-input-placeholder-color: var(--sportsbook-text-main);
	--sportsbook-search-input-icon-color: var(--sportsbook-text-main);
	--sportsbook-search-input-color: var(--sportsbook-text-main);
	--sportsbook-search-result-border-color: var(--sportsbook-border-primary);
	--sportsbook-search-close-button-text-color: var(--sportsbook-text-contrast);
	--sportsbook-search-filter-pill-bg-color: var(--sportsbook-bg-tertiary);

	// Sportsbook Maintenance
	--sportsbook-maintainance-product-bg-color: var(--sportsbook-bg-accent);
	--sportsbook-maintainance-product-icon-color: var(--sportsbook-text-contrast);
	--sportsbook-maintainance-product-label-color: var(--sportsbook-text-main);
	--sportsbook-maintainance-border-color: var(--sportsbook-border-primary);

	// Event Widget
	--sportsbook-event-widget-bar-bg: var(--sportsbook-bg-primary);
	--sportsbook-event-widget-button-color: var(--sportsbook-text-main); // actually, icon color
	--sportsbook-event-widget-button-color-active: var(--sportsbook-icon-primary);

	// Betbuilder
	--sportsbook-betbuilder-close-icon: var(--sportsbook-icon-primary);

	// Other
	--sportsbook-live-color: rgba(var(--primary-light), 1);
	--sportsbook-live-text-color: var(--sportsbook-text-contrast);
	--sportsbook-no-odds-available: var(--sportsbook-text-main);
	--sportsbook-horizontal-filter-bar-text-color: var(--sportsbook-text-supportive);
	--sportsbook-horizontal-filter-bar-selected-color: var(--sportsbook-text-accent);
	--sportsbook-navigation-tab-color: var(--sportsbook-text-supportive);
	--sportsbook-navigation-tab-active-color: var(--sportsbook-text-accent);
	--sportsbook-navigation-tab-border-color: var(--sportsbook-border-primary);
	--sportsbook-header-title-color: var(--sportsbook-text-main);
	--sportsbook-highlighted-label-bg: rgba(var(--info), 1);
	--sportsbook-highlighted-label-color: var(--sportsbook-text-contrast);
	--sportsbook-connection-border-color: var(--sportsbook-text-supportive);
	--sportsbook-tennis-serve-indicator-color: rgba(var(--primary-light), 1);
	--sportsbook-loading-spinner-color: var(--sportsbook-text-main);
	--sportsbook-tooltip-bg: var(--sportsbook-bg-secondary);
	--sportsbook-odds-updating-bg: rgba(var(--primary-accent-grey-light), 1);
}
